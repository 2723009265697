import { RadixNetwork } from '@radixdlt/radix-dapp-toolkit';
import { BigNumber } from 'bignumber.js';

export const RADIX_GATEWAY_URL = 'https://gateway.radix.live'; //'https://gateway-api.astrolescent.com';
export const RADIX_NETWORK_ID = RadixNetwork.Mainnet;
export const D1_DATABASE = 'HUGS_DB';

export const DAPP_DEFINITION_ACCOUNT_ADDRESS = 'account_rdx129nv5getrdnyd6weupmlm3cszszdjz2d24gq3mvrr3mpcgvnz9qtl0';
export const CLAIM_COMPONENT_ADDRESS = 'component_rdx1cqg388mnn3qq46lsntk952v06jtlx0f54x7h9helafvx5mrsu8v3hk';
export const STAKING_COMPONENT_ADDRESS = 'component_rdx1cqzk2672ppnz29ska2xgr4697947w82nmnajtjvntg82e3nx0jhryw';
export const FEE_COMPONENT_ADDRESS = 'component_rdx1cqrj4877r6kv9rmyntu0c9u34t8aed5q4nf726632fhydwmgxctcfd';
export const STAKING_POOL_ADDRESS = 'pool_rdx1cs3x8m0er4seq7penvqqv0z0k38da8m3t89vhnlj0pcackdwdmgzay';
export const CLAIM_NFT_ADDRESS = "resource_rdx1ntdde5xlmy0lpzlj707j294egru3tc7t6dy2udxk4f9kt49k2saj9s";

export const TIP_OWNER_BADGE = 'resource_rdx1nf2pg062nmmcnp83575dchlsy6kxmrfnsvhnctc5y2urq2yrg0xhgq:#1#';
export const CLAIM_OWNER_BADGE = 'resource_rdx1ng2nl9uy7z350j426jxz5eafu0txwjw37kty020glsqjw7nm7apprv';

// export const RADIX_GATEWAY_URL = 'https://babylon-stokenet-gateway.radixdlt.com'; //'https://gateway-api.astrolescent.com';
// export const RADIX_NETWORK_ID = RadixNetwork.Stokenet;
// export const D1_DATABASE = 'HUGS_DB';

// export const DAPP_DEFINITION_ACCOUNT_ADDRESS = 'account_tdx_2_12ycsedykz4ke8l09k53y3gn2rs8hne8kens5kg4rn3gup33k0uld76';
// export const CLAIM_COMPONENT_ADDRESS = 'component_tdx_2_1cpdavqeknds2ky65e0nkdw2zl0kt04y4qws7tlpjrqn8r6mzazrmp9';
// export const CLAIM_NFT_ADDRESS = "resource_tdx_2_1ngvehkkg4y3nfhczym948eqxjwh6dm2z03ycq8urja3eh2qqqdn37z";

export const HUG_RESOURCE_ADDRESS = 'resource_rdx1t5kmyj54jt85malva7fxdrnpvgfgs623yt7ywdaval25vrdlmnwe97';
export const sHUG_RESOURCE_ADDRESS = 'resource_rdx1t4qdd8wzl50f07druj4aafcv5pkxa937wr346zl9hx7zuzyl5eertw';
export const XRD_RESOURCE_ADDRESS = 'resource_rdx1tknxxxxxxxxxradxrdxxxxxxxxx009923554798xxxxxxxxxradxrd';

export const DEFIPLAZA_LP_ADDRESS = 'resource_rdx1t47tlukam7rmqnjpf96tula9d25cw74f4aje7948t9rx5ayv45zjuh';
export const DEFIPLAZA_POOL_ADDRESS = 'pool_rdx1c4ycmx5s3y2nw6lcwwjyf533w6yav2k9ujrv7geseh7zylfgxt8jvk';
export const OCISWAP_LP_ADDRESS = 'resource_rdx1t5suu53gjzj2fx2tphgeqk4z5k2mygjw2fr7gel6vpxqw50hwpnvny';
export const OCISWAP_POOL_ADDRESS = 'pool_rdx1c57rem0vrrv3wh7c5cjz63ww7se0a3cu9f7zd8az2qxtphgu72r0as';
export const VALIDATOR_ADDRESS = 'validator_rdx1sdd0s7vy83qtqevqj7dgkuf660hfmf6xn4dl6t8rasg8njwh4nrx55';

export const RADIXAPI_TOKEN = 'nHouoY0MBdBib_o0Xxl9YrcLl1QCMVOA7_R3zMHfbqc';

export const CLAIM_TYPE_VALIDATOR = 1;
export const CLAIM_TYPE_DEFIPLAZA = 2;
export const CLAIM_TYPE_OCISWAP = 3;
export const CLAIM_TYPE_STAKING = 4;
export const CLAIM_TYPE_BOT = 5;
export const CLAIM_TYPE_ROOT = 6;

export const CLAIM_TYPES = [
	{ type: '1', value: 'HUG Validator', link: 'https://dashboard.radixdlt.com/network-staking/validator_rdx1sdd0s7vy83qtqevqj7dgkuf660hfmf6xn4dl6t8rasg8njwh4nrx55', button: 'Add' },
	{ type: '4', value: 'HUG Staking', link: '/staking', button: 'Add' },
	{ type: '2', value: 'DefiPlaza liquidity', link: 'https://radix.defiplaza.net/liquidity/add/resource_rdx1t5kmyj54jt85malva7fxdrnpvgfgs623yt7ywdaval25vrdlmnwe97?direction=base', button: 'Add' },
	{ type: '3', value: 'Ociswap liquidity', link: 'https://ociswap.com/pools/component_rdx1cr8hdtxhz7k6se6pgyrqa66sdlc06kjchfzjcl6pl2er8ratyfyre8', button: 'Add' },
	{ type: '5', value: 'HUGbot Trading', link: 'https://t.me/hugmemebot', button: 'Trade' },
	{ type: '6', value: 'Root Finance', link: 'https://app.rootfinance.xyz/', button: 'Add' },

];

export const TEAM_WALLETS = {
	"account_rdx12y9lqw4897z97zakcy2zqdrm4djrzp8yzsnf935pz6ju7e9a8qj2p3": 'HUG Donations',
	'account_rdx12ylvsgmflcvsnwwk8wfqevfv9a35qjwf53vmajydu26j29evz6wn6g': 'PoH Reserves',
	'account_rdx12yh80l3ppudsupxrv6a7xey9ephdeq2jh0yjmhzmyvkvnw00d2rttp': 'Marketing, Promotions, and Fun',
	'account_rdx12yw2hvku6g4jef9gngze734tagqf2vwjrn5qnw99fcvzys53358r0x': 'Team Reserves',
	'account_rdx129hs2e3kzqeq9787kykggraqhxawqkdkea2rk5skp8p24lz65h24v4': 'Founder Reserves',
	'account_rdx16xet2vwd69kwurwcsrmynngf0crf3ndqr5dzqh2kunhvlrtr0jsj80': 'Liquidity and Treasury'
}

/// how many hugs is receiing a hug worth?
export const BOT_RECEIVE_MULTIPLIER = 2

// Yearly hug is 15B, daily 40M
export const yearlyHugs = 15000000000;
export const dailyHug = yearlyHugs / 365;
export const hourlyHug = dailyHug / 24;
export const CLAIM_AMOUNTS = {
	'1': 0.4,		// Validator
	'2': 0.2,		// Liquidity DefiPlaza
	'3': 0.2,		// Liquidity Ociswap
	'4': 0.1,		// HUG Staking
	'5': 0.08,		// HUG bot
	'6': 0.02,		// Root
}

console.log('Total PoH allocations: ', Object.values(CLAIM_AMOUNTS).reduce((total, a) => total + a, 0))
if (Object.values(CLAIM_AMOUNTS).reduce((total, a) => total + a, 0) > 1) {
	throw new Error('🚨 Critical error, total PoH larger than 100%');
}

export const CLAIM_AMOUNT_VALIDATOR = hourlyHug * CLAIM_AMOUNTS['1'];
export const CLAIM_AMOUNT_DEFIPLAZA = hourlyHug * CLAIM_AMOUNTS['2'];
export const CLAIM_AMOUNT_OCISWAP = hourlyHug * CLAIM_AMOUNTS['3'];
export const CLAIM_AMOUNT_STAKING = hourlyHug * CLAIM_AMOUNTS['4'];
export const CLAIM_AMOUNT_BOT = dailyHug * CLAIM_AMOUNTS['5'];
export const CLAIM_AMOUNT_ROOT = hourlyHug * CLAIM_AMOUNTS['6'];

export const bigZero = new BigNumber(0);
export const oneE18 = new BigNumber(10).pow(18);
export const oneMinusE18 = new BigNumber(10).pow(-18);
export const isBrowser = typeof document !== 'undefined';
export const ROUND_UP = 0;

export function shortenAddress(address: string | null): string {
	if (!address) {
		return '';
	}

	return address.substring(0, 7) + '...' + address.substring(address.length - 6);
}

export function prettyValue(value: number): string {
	var size = 'M';
	var prettyValue;
	var value = Math.abs(value);

	if (value < 1) {
		prettyValue = value.toFixed(3);
		size = '';
	} else if (value < 1000) {
		prettyValue = value.toFixed(2);
		size = '';
	} else if (value < 10000) {
		prettyValue = Math.floor(value);
		size = '';
	} else if (value < 100000) {
		prettyValue = (value / 1000).toFixed(1);
		size = 'K';
	} else if (value < 1000000) {
		prettyValue = Math.floor(value / 1000);
		size = 'K';
	} else if (value < 10000000) {
		// 10M
		prettyValue = (value / 1000000).toFixed(2);
	} else if (value < 1000000000) {
		prettyValue = (value / 1000000).toFixed(1);
	} else {
		prettyValue = (value / 1000000000).toFixed(1);
		size = 'B'
	}

	return prettyValue.toLocaleString() + size;
}

export function classNames(...classes: string[]) {
	return classes.filter(Boolean).join(' ');
}